import styles from './svgicons.module.css'

export default function SVGEnvelope({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
  className?: string
}) {
  return (
    <svg
      fill={bgColor}
      width={size.width}
      height={size.height}
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={(hasHighlight ? styles.highlightOnHover : '', className)}
    >
      <path data-name="layer2" d="M59.1 12H5l27 24.7L59.1 12z" />
      <path data-name="layer1" d="M32 42.1L2 14.7V52h60V14.7L32 42.1z" />
    </svg>
  )
}
