import clsx from 'clsx'
import styles from './svgicons.module.css'

export default function SVGClose({
  size = { width: '18px', height: '10px' },
  color = 'black',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  className?: string
}) {
  return (
    <svg
      className={className}
      width={size.width}
      height={size.height}
      viewBox="0 0 14 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" strokeLinecap="square">
        <g
          className={clsx(styles.svgCloseHover, className)}
          transform="translate(-6.000000, -7.000000)"
          stroke={color}
        >
          <path
            d="M13,4.97918472 L13,20.9791847 M5,12.9791847 L21,12.9791847"
            transform="translate(13.000000, 12.979185) rotate(45.000000) translate(-13.000000, -12.979185) "
          />
        </g>
      </g>
    </svg>
  )
}
