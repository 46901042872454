'use client'

import { Button, Flex } from '@radix-ui/themes'
import ZbLink from '@/components/ZbLink'
import styles from './pillbutton.module.css'
import clsx from 'clsx'

export default function PillButton({
  active,
  badge,
  badgeContent = '%',
  disabled = false,
  id = '',
  onPillClick,
  text,
  link,
}: {
  active?: boolean
  badge?: boolean
  badgeContent?: string
  disabled?: boolean
  id: string
  onPillClick?: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void
  text: string
  link?: string
}) {
  return (
    <Flex>
      {link ? (
        <ZbLink size="medium" to={link} type="unstyled">
          <Button
            className={clsx(
              styles.pillButton,
              active && styles.activePillButton,
              disabled && styles.disabledPillButton
            )}
            disabled={disabled}
            title={text}
            onClick={
              onPillClick ? (event) => onPillClick(event, id) : undefined
            }
          >
            <span className={styles.pillSpan}>{text}</span>
          </Button>
        </ZbLink>
      ) : (
        <Button
          className={clsx(
            styles.pillButton,
            active && styles.activePillButton,
            disabled && styles.disabledPillButton
          )}
          disabled={disabled}
          title={text}
          onClick={onPillClick ? (event) => onPillClick(event, id) : undefined}
        >
          <span className={styles.pillSpan}>{text}</span>
        </Button>
      )}
    </Flex>
  )
}
