/* eslint-disable import/no-anonymous-default-export */
export default {
  dev: {
    name: null,
    url: null,
    locales: ['de', 'fr', 'es', 'en'],
    defaultLocale: 'de',
    quicklinkMail: 'contact@zebrabox.com',
    veUrl: '/de/volumenrechner',
    labels: {
      de: 'Deutsch',
      fr: 'Français',
      es: 'Español',
      en: 'English',
    },
  },
  ch: {
    name: 'Schweiz',
    url: '',
    countryUrl: 'https://www.zebrabox.ch',
    quicklinkMail: 'contact@zebrabox.com',
    locales: ['de', 'fr', 'en'],
    defaultLocale: 'de',
    veUrl: '/de/volumenrechner',
    veEngUrl: '/en/volume-estimator',
    labels: {
      de: 'Deutsch',
      fr: 'Français',
      en: 'English',
    },
  },
  es: {
    name: 'España',
    url: '',
    countryUrl: 'https://www.zebrabox.es',
    quicklinkMail: 'contact@zebrabox.com',
    locales: ['es', 'en'],
    defaultLocale: 'es',
    veUrl: '/es/estimador-de-volumen',
    veEngUrl: '/en/volume-estimator',
    labels: {
      es: 'Español',
      en: 'English',
    },
  },
  fr: {
    name: 'France',
    url: '',
    countryUrl: 'https://www.zebrabox.fr',
    quicklinkMail: 'contact@zebrabox.com',
    locales: ['fr', 'en'],
    defaultLocale: 'fr',
    veUrl: '/fr/calculateur-volume-stockage',
    veEngUrl: '/en/volume-estimator',
    labels: {
      fr: 'Français',
      en: 'English',
    },
  },
}
