import { defineRouting } from 'next-intl/routing'
import { createNavigation } from 'next-intl/navigation'
import { countryCode } from '@/lib/config'
import i18nConfig from '@/i18n/i18n.config'

const config = i18nConfig[countryCode ?? 'dev']

export default config

export const routing = defineRouting({
  locales: config.locales,
  defaultLocale: config.defaultLocale,
  localeDetection: true,
  alternateLinks: false,
})

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing)
