export const drupalBaseUrl = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL || ''
export const imageDomain = process.env.NEXT_PUBLIC_IMAGE_DOMAIN || ''
export const volumeEstimatorBaseUrl =
  process.env.NEXT_PUBLIC_VOLUMEESTIMATOR_BASE_URL || ''
export const nodeEnv = process.env.NODE_ENV || 'development'
export const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE || 'ch'
export const countries = process.env.NEXT_PUBLIC_COUNTRIES
  ? process.env.NEXT_PUBLIC_COUNTRIES.split(',')
  : ['es', 'fr', 'ch']
export const drupalRevalidateSecret =
  process.env.DRUPAL_REVALIDATE_SECRET || 'secret'

export const COOKIEBOT_CONFIG = {
  ID: process.env.NEXT_PUBLIC_COOKIEBOT_ID,
  ELEMENTS: {
    SCRIPT_ID: 'Cookiebot',
    DECLARATION_ID: 'CookieDeclaration',
    CONTAINER_ID: 'cookie-declaration'
  }
} as const

const config = {
  drupalBaseUrl,
  imageDomain,
  volumeEstimatorBaseUrl,
  nodeEnv,
  countryCode,
  countries,
  drupalRevalidateSecret,
}

export default config
