'use client'

import { createContext, useContext, ReactNode } from 'react'

interface TranslationResources {
  general_all: string
  general_next: string
  general_select: string
  general_overview: string
  quicklink_phoneBtnText: string
  quicklink_emailBtnText: string
  volumecalc_rentABox: string
  volumecalc_url: string
  volumecalc_bookNow: string
  blog_copiedToClipboard: string
  blog_allTopics: string
  blog_lastArticles: string
  blog_articleFetchError: string
  blog_articlesError: string
  blog_noArticles: string
  blog_categories: string
  blog_categoriesFetchError: string
  blog_allCategories: string
  blog_categoriesError: string
  blog_otherTopics: string
  blog_blogLink: string
  blog_featured: string
  blog_featuredFetchError: string
  footer_socialNetworks: string
  locations_viewAllLocationsText: string
  locations_viewAllLocationsUrl: string
  locations_map: string
  locations_cards: string
  locations_details: string
  home_searchForYourNearestLocation: string
  search_btn: string
  search_inputPlaceholder: string
  search_noResults: string
  search_noQueryParameter: string
  search_searchResultsTitle: string
  menu_closeToYou: string
  menu_ourServices: string
  menu_moreServices: string
  menu_learnMore: string
  menu_latestArticlesListTitle: string
  menu_moreTipsAndTricks: string
  menu_seeAllArticles: string
  menu_straightToThePoint: string
  contactform_titlemain: string
  contactform_section1: string
  contactform_section2: string
  contactform_salutaion_label: string
  contactform_salutaion_error: string
  contactform_salutation_option_mr: string
  contactform_salutation_option_mrs: string
  contactform_firstname_label: string
  contactform_firstname_error: string
  contactform_lastname_label: string
  contactform_lastname_error: string
  contactform_street_label: string
  contactform_postalcode_label: string
  contactform_city_label: string
  contactform_email_label: string
  contactform_email_error_empty: string
  contactform_email_error_invalid: string
  contactform_phone_label: string
  contactform_location_label: string
  contactform_location_error: string
  contactform_category_label: string
  contactform_category_error: string
  contactform_category_option_storageunit: string
  contactform_category_option_question: string
  contactform_category_option_van: string
  contactform_category_option_packagingmaterial: string
  contactform_category_option_terminate: string
  contactform_category_option_other: string
  contactform_remarks_label: string
  contactform_send_label: string
  smallform_title: string
  smallform_writeus: string
  smallform_email_label: string
  smallform_email_error: string
  smallform_email_error_empty: string
  smallform_subject_label: string
  smallform_message_label: string
  smallform_send_label: string
  smallform_captcha_expired: string
  smallform_captcha_error: string
  smallform_captcha_success: string
  smallform_success: string
  faq_allTopics: string
  contact_cta_phone_title: string
  contact_cta_write_title: string
  contact_cta_write_text: string
  contact_cta_write_email: string
  contact_cta_write_contactform: string
  contact_cta_contactform_url: string
}

interface ResourcesContextProps {
  resources: TranslationResources
}

const ResourcesContext = createContext<ResourcesContextProps | null>(null)

export const ResourcesProvider = ({
  resources,
  children,
}: {
  resources: TranslationResources
  children: ReactNode
}) => {
  return (
    <ResourcesContext.Provider value={{ resources }}>
      {children}
    </ResourcesContext.Provider>
  )
}

export const useResources = () => {
  const context = useContext(ResourcesContext)
  if (!context) {
    throw new Error('useResources must be used within a ResourcesProvider')
  }
  return context.resources
}
