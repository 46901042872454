import clsx from 'clsx'
import styles from './svgicons.module.css'

export default function SVGArrow({
  size = { width: '18px', height: '10px' },
  color = 'black',
  direction = 'right',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  direction?: 'up' | 'down' | 'left' | 'right'
  className?: string
}) {
  const arrowStyle = styles[direction + 'Arrow']

  return (
    <svg
      className={clsx(arrowStyle, styles.svgClass, className)}
      width={size.width}
      height={size.height}
      viewBox="0 0 24 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-12.000000, -16.000000)">
          <g transform="translate(12.000000, 16.000000)">
            <g transform="translate(0.000000, -0.000000)">
              <polygon points="19.3043478 2.63768114 12 -4.66231887 4.69565215 2.63768114 5.19367587 3.10434781 11.6679842 -3.3956522 11.6679842 19.2710145 12.3320158 19.2710145 12.3320158 -3.3956522 18.8063241 3.10434781" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
