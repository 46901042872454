'use client'

import { createContext, useContext } from 'react'

export interface AppSettingsProps {
  translations?: {
    [key: string]: string
  }
  quicklinks?: {
    [key: string]: string
  }
  social?: {
    [key: string]: string
  }
  footer?: {
    [key: string]: string
  }
  frontPageNid?: string
}

const AppSettingsContext = createContext<{
  appSettings: AppSettingsProps | null
}>({
  appSettings: null,
})

export function AppSettingsProvider({
  children,
  settings
}: {
  children: React.ReactNode
  settings: AppSettingsProps
}) {
  return (
    <AppSettingsContext.Provider value={{ appSettings: settings }}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export function useAppSettings() {
  return useContext(AppSettingsContext)
}
