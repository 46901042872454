import { useMemo } from 'react'
import { parse } from 'node-html-parser'
import DOMPurify from 'isomorphic-dompurify'

export function wrapTables(html: string) {
  const root = parse(html)

  root.querySelectorAll('table').forEach((table) => {
    table.replaceWith(`<div class="custom-scrollbar">${table.toString()}</div>`)
  })

  return root.toString()
}

export function useSanitizedHtml(html: string = '') {
  return useMemo(() => {
    return DOMPurify.sanitize(wrapTables(html))
  }, [html])
}
