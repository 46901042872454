'use client'

import styles from './arrowlinklist.module.css'
import ArrowLink from '@/components/ArrowLink'
import { Flex } from '@radix-ui/themes'
import ZbLink from '@/components/ZbLink'
import clsx from 'clsx'

export default function ArrowLinkList({
  elements,
  title,
  titleElement,
  columns,
  className,
  gap = '15px',
  gapChildren = '0.5rem',
  childrenClassName,
}: {
  elements: Object[] | undefined
  title?: { text?: string; url?: string }
  titleElement?: React.ReactNode
  columns?: number
  className?: string
  gap?: string
  gapChildren?: string
  childrenClassName?: string
}) {
  return (
    <Flex direction="column" gap={gap} width="100%" className={className}>
      <div className={styles.arrowListTitle}>
        {title?.url ? (
          <ZbLink size="medium" to={title?.url} type="unstyled">
            {title?.text}
          </ZbLink>
        ) : title?.text ? (
          <span>{title?.text}</span>
        ) : (
          titleElement
        )}
      </div>
      <div
        className={styles.arrowListContainer}
        style={{
          rowGap: gapChildren,
          gridTemplateColumns: `repeat(${columns}, minmax(150px, 1fr))`,
        }}
      >
        {elements?.map((child: any, index: number) => (
          <div
            className={clsx(styles.arrowListChild, styles[childrenClassName])}
            key={index}
          >
            <ArrowLink key={index} text={child?.title} url={child?.url} />
          </div>
        ))}
      </div>
    </Flex>
  )
}
