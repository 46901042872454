import styles from './svgicons.module.css'

export default function SVGFacebook({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 600 600"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle
        fill={bgColor}
        cx="300"
        cy="300"
        r="300"
        className={hasHighlight ? styles.highlightOnHover : ''}
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M261.16,485.71h71.96v-165.99h53.15l10.63-67.05h-63.78v-50.7c0-18.81,17.99-29.44,35.16-29.44h31.07v-55.6l-55.6-2.45c-53.15-3.27-82.59,38.43-82.59,88.31v49.88h-60.51v67.05h60.51v165.99Z"
      />
    </svg>
  )
}
