import styles from './svgicons.module.css'

export default function SVGChevron({
  size = { width: '18px', height: '10px' },
  color = 'black',
  direction = 'right',
}: {
  size?: { width: string; height: string }
  color?: string
  direction?: 'up' | 'down' | 'left' | 'right'
}) {
  const chevronStyle = styles[direction]

  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 18 10"
      className={chevronStyle}
      fill={color}
    >
      <path d="M13.4682181,4.08385959 L5.8892871,-3.96918801 C5.5354041,-4.33496421 4.9621162,-4.37725821 4.5497488,-4.00619531 C4.1809952,-3.67411911 4.1717412,-3.02683351 4.5127415,-2.66631871 L10.596492,3.78784329 C10.8517434,4.07068359 10.9793691,4.38645129 10.9793691,4.73514629 C10.9793691,5.08407249 10.8517434,5.39984009 10.596492,5.68244919 L4.5127415,12.1366112 C4.1717497,12.4971006 4.1958658,13.1285767 4.5497488,13.4761495 C4.9231295,13.8432453 5.5410376,13.810535 5.8892871,13.4391422 L13.4682181,5.38643289 C13.6909218,5.16802619 13.7315664,4.95754549 13.7351953,4.73517159 C13.7351953,4.51638429 13.6026963,4.22528259 13.4682181,4.08385959 Z" />
    </svg>
  )
}
