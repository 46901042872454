export default function SVGSearch({
  size = { width: '18px', height: '10px' },
  color = 'black',
  className,
}: {
  size?: { width: string; height: string }
  color?: string
  className?: string
}) {
  return (
    <svg
      className={className}
      width={size.width}
      height={size.height}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={color}
    >
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-13.000000, -13.000000)">
          <path d="M21.7241379,13 C26.5423463,13 30.4482759,16.9059296 30.4482759,21.7241379 C30.4482759,23.9484077 29.6158833,25.9782618 28.2456128,27.5191856 L35,34.2742396 L34.2742396,35 L27.5191856,28.2456128 C25.9782618,29.6158833 23.9484077,30.4482759 21.7241379,30.4482759 C16.9059296,30.4482759 13,26.5423463 13,21.7241379 C13,16.9059296 16.9059296,13 21.7241379,13 Z M21.7241379,14.0905172 C17.5082056,14.0905172 14.0905172,17.5082056 14.0905172,21.7241379 C14.0905172,25.9400702 17.5082056,29.3577586 21.7241379,29.3577586 C25.9400702,29.3577586 29.3577586,25.9400702 29.3577586,21.7241379 C29.3577586,17.5082056 25.9400702,14.0905172 21.7241379,14.0905172 Z" />
        </g>
      </g>
    </svg>
  )
}
