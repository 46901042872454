'use client'

import ZbLink from '@/components/ZbLink'
import SVGArrow from '@/components/SVGIcons/SVGArrow'

export default function ArrowLink({
  text,
  url,
}: {
  text: string
  url: string
}) {
  return (
    <ZbLink size="medium" to={url} type="arrow">
      <span>{text}</span>
      <SVGArrow size={{ width: '16px', height: '16px' }} />
    </ZbLink>
  )
}
