'use client'

import i18n from '@/i18n/routing'

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

type DrupalAlternates = {
  alternates: {
    [key: string]: string
  }
}

type DrupalContextType = {
  alternates?: DrupalAlternates
  setAlternates: (alternates: DrupalAlternates) => void
}

const defaultDrupalContext: DrupalContextType = {
  alternates: undefined,
  setAlternates: () => {},
};

const DrupalContext = createContext<DrupalContextType>(defaultDrupalContext);

export function useDrupalContext() {
  const context = useContext(DrupalContext)

  if (!context) {
    throw new Error('useDrupalContext must be used within a DrupalProvider')
  }

  return context
}

export function DrupalContextProvider({ children }: { children: ReactNode }) {
  const [alternates, setAlternates] = useState<DrupalAlternates | undefined>(undefined);

  return (
    <DrupalContext.Provider value={{ alternates, setAlternates }}>
      {children}
    </DrupalContext.Provider>
  );
}

export function SetDrupalContextAlternates({
  alternates,
}: {
  alternates: any
}) {
  const { setAlternates } = useDrupalContext()

  useEffect(() => {
    let updatedAlternates: Array<{ href: string; matchedLocale: string | null; sectionAfterLocale: string }> = [];

    if (alternates) {
      const localePattern = new RegExp(`(${i18n.locales.join('|')})/(.*)`);

      updatedAlternates = alternates.map((alternate) => {
        const { href } = alternate;
        const match = href.match(localePattern);
        const matchedLocale = match ? match[1] : null;
        const sectionAfterLocale = match ? `/${match[2]}` : href;

        return {
          ...alternate,
          matchedLocale,
          sectionAfterLocale,
        };
      });
    }

    const alternatesObject = updatedAlternates.reduce((acc, alternate) => {
      if (alternate.matchedLocale && alternate.href.includes('{{domain}}')) {
        acc[alternate.matchedLocale] = alternate.sectionAfterLocale;
      }
      return acc;
    }, {} as { [key: string]: string });

    setAlternates({ alternates: alternatesObject });
  }, [alternates, setAlternates]);

  return null
}
